import React from 'react'
import './form.sass'
import { navigate } from '@reach/router'

export default ({ handleSubmit, handleUpdate }) => (
  <form
    className="form"
    method="post"
    onSubmit={(event) => {
      handleSubmit(event)
      navigate(`/form/gonder`)
    }}
  >
    <p className="form__instructions">
      Lütfen mail adresinizde gönderdiğimiz kullanıcı adı ve şifre ile giriş
      yapınız.
    </p>
    <label className="form__label">
      Kullanıcı Adı
      <input
        className="form__input"
        type="text"
        name="username"
        onChange={handleUpdate}
      />
    </label>
    <label className="form__label">
      Şifre
      <input
        className="form__input"
        type="password"
        name="password"
        onChange={handleUpdate}
      />
    </label>
    <input className="form__button" type="submit" value="Giriş Yap" />
  </form>
)
