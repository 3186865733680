import React from 'react'
import { Router } from '@reach/router'
import Gonder from '../components/Gonder'
import Login from '../components/Login'
import PrivateRoute from '../components/PrivateRoute'

const Form = () => (
  <div>
    <Router>
      <PrivateRoute path="/form/gonder" component={Gonder} />
      <Login path="/form/login" />
    </Router>
  </div>
)

export default Form
