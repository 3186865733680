import React, { useRef, useState } from 'react'
import Layout from '../components/Layout'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import downloadFile from '../../static/artvizit-kisisel-veri-aydinlatma-metni(AT).pdf'

const Gonder = () => {
  const { register, handleSubmit, errors } = useForm()
  const [eser, setEser] = useState(false)
  const [eser2, setEser2] = useState(false)
  const [eser3, setEser3] = useState(false)
  const [eser4, setEser4] = useState(false)
  const [eser5, setEser5] = useState(false)
  const [karakter, setKarakter] = useState(0)
  let btnRef = useRef()

  const onSosMedyaChange = () => {
    const fileInput = document.querySelector(
      '#file-js-sosMedya input[type=file]'
    )
    if (fileInput.files.length > 0) {
      let fileSize = fileInput.files[0].size / 100000
      const fileName = document.querySelector('#file-js-sosMedya .file-name')
      if (fileSize > 5) {
        alert('Fotoğraf en fazla 500 Kilobyte (0.5 Megabyte) olmalıdır')
        fileName.textContent = 'Tekrar Dosya yükleyiniz'
        fileInput.value = ''
      } else {
        fileName.textContent = fileInput.files[0].name
      }
    }
  }
  const onFileChange = () => {
    const fileInput = document.querySelector(
      '#file-js-example input[type=file]'
    )
    if (fileInput.files.length > 0) {
      let fileSize = fileInput.files[0].size / 100000
      const fileName = document.querySelector('#file-js-example .file-name')
      if (fileSize > 5) {
        alert('Fotoğraf en fazla 500 Kilobyte (0.5 Megabyte) olmalıdır')
        fileName.textContent = 'Tekrar Dosya yükleyiniz'
        fileInput.value = ''
      } else {
        fileName.textContent = fileInput.files[0].name
      }
    }
  }

  const onFileChange2 = () => {
    const fileInput = document.querySelector(
      '#file-js-example2 input[type=file]'
    )
    if (fileInput.files.length > 0) {
      let fileSize = fileInput.files[0].size / 100000
      const fileName = document.querySelector('#file-js-example2 .file-name')
      if (fileSize > 5) {
        alert('Fotoğraf en fazla 500 Kilobyte (0.5 Megabyte) olmalıdır')
        fileName.textContent = 'Tekrar Dosya yükleyiniz'
        fileInput.value = ''
      } else {
        fileName.textContent = fileInput.files[0].name
      }
    }
  }

  const onFileChange3 = () => {
    const fileInput = document.querySelector(
      '#file-js-example3 input[type=file]'
    )
    if (fileInput.files.length > 0) {
      let fileSize = fileInput.files[0].size / 100000
      const fileName = document.querySelector('#file-js-example3 .file-name')
      if (fileSize > 5) {
        alert('Fotoğraf en fazla 500 Kilobyte (0.5 Megabyte) olmalıdır')
        fileName.textContent = 'Tekrar Dosya yükleyiniz'
        fileInput.value = ''
      } else {
        fileName.textContent = fileInput.files[0].name
      }
    }
  }

  const onFileChange4 = () => {
    const fileInput = document.querySelector(
      '#file-js-example4 input[type=file]'
    )
    if (fileInput.files.length > 0) {
      let fileSize = fileInput.files[0].size / 100000
      const fileName = document.querySelector('#file-js-example4 .file-name')
      if (fileSize > 5) {
        alert('Fotoğraf en fazla 500 Kilobyte (0.5 Megabyte) olmalıdır')
        fileName.textContent = 'Tekrar Dosya yükleyiniz'
        fileInput.value = ''
      } else {
        fileName.textContent = fileInput.files[0].name
      }
    }
  }

  const onFileChange5 = () => {
    const fileInput = document.querySelector(
      '#file-js-example5 input[type=file]'
    )
    if (fileInput.files.length > 0) {
      let fileSize = fileInput.files[0].size / 100000
      const fileName = document.querySelector('#file-js-example5 .file-name')
      if (fileSize > 5) {
        alert('Fotoğraf en fazla 500 Kilobyte (0.5 Megabyte) olmalıdır')
        fileName.textContent = 'Tekrar Dosya yükleyiniz'
        fileInput.value = ''
      } else {
        fileName.textContent = fileInput.files[0].name
      }
    }
  }

  const handleChange = () => {
    setEser(!eser)
  }
  const handleChange2 = () => {
    setEser2(!eser2)
  }
  const handleChange3 = () => {
    setEser3(!eser3)
  }
  const handleChange4 = () => {
    setEser4(!eser4)
  }
  const handleChange5 = () => {
    setEser5(!eser5)
  }

  const onSubmit = (data, e) => {
    let ok = true
    let formData = new FormData()
    for (let [key, value] of Object.entries(data)) {
      if (
        key === 'sosMedya' &&
        data['sosMedya'].length > 0 &&
        data['sosMedya'][0].type
      ) {
        formData.append('sosMedya', data['sosMedya'][0])
      } else if (
        key === 'bireser' &&
        data['bireser'].length > 0 &&
        data['bireser'][0].type
      ) {
        formData.append('bireser', data['bireser'][0])
      } else if (
        key === 'ikieser' &&
        data['ikieser'].length > 0 &&
        data['ikieser'][0].type
      ) {
        formData.append('ikieser', data['ikieser'][0])
      } else if (
        key === 'uceser' &&
        data['uceser'].length > 0 &&
        data['uceser'][0].type
      ) {
        formData.append('uceser', data['uceser'][0])
      } else if (
        key === 'dorteser' &&
        data['dorteser'].length > 0 &&
        data['dorteser'][0].type
      ) {
        formData.append('dorteser', data['dorteser'][0])
      } else if (
        key === 'beseser' &&
        data['beseser'].length > 0 &&
        data['beseser'][0].type
      ) {
        formData.append('beseser', data['beseser'][0])
      } else {
        if (key === 'sosMedya' && data['sosMedya'].length === 0) {
          ok = false
          alert('Sosyal medya için görsel eklemeniz gerekiyor')
        }
        if (
          key === 'birsecim' &&
          value === 'gorsel' &&
          data['bireser'].length === 0
        ) {
          ok = false
          alert('Birinci eser için görsel eklemeniz gerekiyor')
        }
        if (
          key === 'ikisecim' &&
          value === 'gorsel' &&
          data['ikieser'].length === 0
        ) {
          ok = false
          alert('İkinci eser için görsel eklemeniz gerekiyor')
        }
        if (
          key === 'ucsecim' &&
          value === 'gorsel' &&
          data['uceser'].length === 0
        ) {
          ok = false
          alert('Üçüncü eser için görsel eklemeniz gerekiyor')
        }
        if (
          key === 'dortsecim' &&
          value === 'gorsel' &&
          data['dorteser'].length === 0
        ) {
          ok = false
          alert('Dördüncü eser için görsel eklemeniz gerekiyor')
        }
        if (
          key === 'bessecim' &&
          value === 'gorsel' &&
          data['beseser'].length === 0
        ) {
          ok = false
          alert('Beşinci eser için görsel eklemeniz gerekiyor')
        }
        formData.append(key, value)
      }
    }

    if (ok) {
      if (btnRef.current) {
        btnRef.current.setAttribute('disabled', 'disabled')
      }
      axios
        .post(
          'https://artvizit-efmapcex5q-ew.a.run.app/upload',
          // "http://localhost:8080/upload",
          formData,
          {
            headers: {
              'Content-type': 'multipart/form-data',
            },
          }
        )
        .then((res) => {
          if (typeof window !== `undefined`)
            window.location.replace(`/tesekkurler`)
        })
        .catch((err) => {
          console.log(err)
          alert('Bir hata oluştu, lütfen bir süre sonra tekrar deneyiniz!')
          if (btnRef.current) {
            btnRef.current.removeAttribute('disabled')
          }
        })
    }
  }

  return (
    <Layout>
      <div className="formKonteyner">
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="label" htmlFor="isim">
            Bir sanatçı olarak seni tanıtan ve herkesin görebileceği bir
            artvizit yaratmak için aşağıdaki bilgilerin tamamını Türkçe dilinde
            girmeni rica ederiz.
          </label>
          <div className="field">
            <label className="label" htmlFor="isim">
              Adınız
            </label>
            <input
              className="input is-info"
              name="isim"
              placeholder="Adınız"
              ref={register({
                validate: (value) => value.length >= 3,
              })}
            />
            {errors.isim && (
              <p className="help is-danger">Lütfen boş bırakmayınız.</p>
            )}
          </div>

          <div className="field">
            <label className="label" htmlFor="soyad">
              Soyadınız
            </label>
            <input
              className="input is-info"
              name="soyad"
              placeholder="Soyadınız"
              ref={register({
                validate: (value) => value.length >= 2,
              })}
            />
            {errors.soyad && (
              <p className="help is-danger">Lütfen boş bırakmayınız.</p>
            )}
          </div>

          <div className="field">
            <label className="label" htmlFor="email">
              E-posta adresiniz
            </label>
            <input
              className="input is-info"
              name="email"
              placeholder="E-posta adresiniz"
              type="email"
              ref={register}
            />
            <p className="help">
              Size ulaşabilmemiz için gerekli, artvizitinizde
              paylaşılmayacaktır.
            </p>
          </div>

          <div className="field">
            <label className="label" htmlFor="firstName">
              Üretim alanınız (En fazla 3 tane, virgül ile ayırarak
              yazabilirsiniz)
            </label>
            <input
              className="input is-info"
              name="uretim"
              placeholder="Üretim alanınız"
              ref={register({
                validate: (value) => value !== '',
              })}
            />
            {errors.uretim && (
              <p className="help is-danger">Lütfen boş bırakmayınız.</p>
            )}
            <p className="help">
              Üretiminizi tanımlayan ve en çok yoğunlaştığınız sanat alanı /
              alanları{' '}
            </p>
          </div>

          <div className="field">
            <label className="label" htmlFor="site">
              Web siteniz (varsa)
            </label>
            <input
              className="input is-info"
              name="site"
              placeholder="Web siteniz"
              ref={register({
                validate: (value) => {
                  if (value !== '') {
                    if (
                      !value.includes('.') ||
                      (!value.includes('http') &&
                        !value.includes('www') &&
                        !value.includes('.com'))
                    ) {
                      return false
                    }
                  }
                  return true
                },
              })}
            />
            {errors.site && (
              <p className="help is-danger">
                Lütfen sitenizi http:// veya www. ile başlayan tam link ile
                giriniz.
              </p>
            )}
          </div>

          <div className="field">
            <label className="label" htmlFor="sosyal">
              Sosyal medya hesabınız (varsa), sadece 1 site giriniz lütfen,
              örnek: www.instagram.com/hesapadı{' '}
            </label>
            <input
              className="input is-info"
              name="sosyal"
              placeholder="Sosyal medya hesabınız"
              ref={register({
                validate: (value) => {
                  if (value !== '') {
                    if (
                      !value.includes('.') ||
                      (!value.includes('http') &&
                        !value.includes('www') &&
                        !value.includes('.com'))
                    ) {
                      return false
                    }
                  }
                  return true
                },
              })}
            />
            {errors.sosyal && (
              <p className="help is-danger">
                Lütfen sosyal medya hesabınızı http:// veya www. ile başlayan
                tam link ile giriniz.
              </p>
            )}
          </div>

          <div className="field">
            <label className="label" htmlFor="beyan">
              Sanatçı beyanınız (Boşluk dahil max. 520 karakter ve 1. kişi
              anlatımı ile yazılmış olmalı)
            </label>
            <textarea
              className="textarea"
              name="beyan"
              rows="7"
              placeholder="Sanatçı beyanınız (Boşluk dahil max. 520 karakter ve 1. kişi anlatımı ile yazılmış olmalı)"
              ref={register({
                validate: (value) => value.length > 300 && value.length < 520,
              })}
              onChange={(event) => {
                const { value } = event.target
                setKarakter(value.length)
              }}
            />
            {karakter > 0 && (
              <p className="help is-info">Karakter Sayısı: {karakter}</p>
            )}
            {errors.beyan && (
              <p className="help is-danger">
                Metnin kabul edilebilmesi için boşluk dahil min 300, max 520
                karakter olmalı.
              </p>
            )}
          </div>

          <div className="field">
            <label className="label" htmlFor="anahtar">
              Bir eseri üretirken aklınızda olan ya da üretiminizi başka birine
              anlatırken kullandığınız, Türkçe dilinde 5 anahtar kelime (konu
              veya kavram)
            </label>
            <input
              className="input is-info"
              name="biranahtar"
              placeholder="1. Anahtar Kelime"
              ref={register({
                validate: (value) => value != '',
              })}
            />
            {errors.biranahtar && (
              <p className="help is-danger">
                Lütfen üretiminizi anlatan anahtar kelime giriniz
              </p>
            )}
            <input
              className="input is-info"
              name="ikianahtar"
              placeholder="2. Anahtar Kelime"
              ref={register({
                validate: (value) => value != '',
              })}
            />
            {errors.ikianahtar && (
              <p className="help is-danger">
                Lütfen üretiminizi anlatan anahtar kelime giriniz
              </p>
            )}
            <input
              className="input is-info"
              name="ucanahtar"
              placeholder="3. Anahtar Kelime"
              ref={register({
                validate: (value) => value != '',
              })}
            />
            {errors.ucanahtar && (
              <p className="help is-danger">
                Lütfen üretiminizi anlatan anahtar kelime giriniz
              </p>
            )}
            <input
              className="input is-info"
              name="dortanahtar"
              placeholder="4. Anahtar Kelime"
              ref={register({
                validate: (value) => value != '',
              })}
            />
            {errors.dortanahtar && (
              <p className="help is-danger">
                Lütfen üretiminizi anlatan anahtar kelime giriniz
              </p>
            )}
            <input
              className="input is-info"
              name="besanahtar"
              placeholder="5. Anahtar Kelime"
              ref={register({
                validate: (value) => value != '',
              })}
            />
            {errors.besanahtar && (
              <p className="help is-danger">
                Lütfen üretiminizi anlatan anahtar kelime giriniz
              </p>
            )}
          </div>

          <p className="label" style={{ marginTop: '20px' }}>
            Sosyal Medya İçin Görsel Yükleme:
          </p>

          <div className="field">
            <label className="label" htmlFor="sosMedya">
              Lütfen sanat üretiminiz esnasında çekilmiş bir görselinizi
              yükleyiniz. Bu görsel, Artvizit.co’nun sosyal medya hesaplarında
              yayınlanmak üzere kullanılacaktır.
            </label>
            <div id="file-js-sosMedya" className="file has-name is-primary">
              <label className="file-label">
                <input
                  ref={register}
                  className="file-input"
                  type="file"
                  name="sosMedya"
                  onChange={onSosMedyaChange}
                />
                <span className="file-cta">
                  <span className="file-label">
                    Sosyal medya görseli için lütfen dosya seçiniz.
                  </span>
                </span>
                <span className="file-name">Henüz Dosya yüklemediniz.</span>
              </label>
            </div>
          </div>

          <p className="label" style={{ marginTop: '20px' }}>
            Üretiminizi en iyi özetlediğini düşündüğünüz 5 eser:
          </p>

          <div className="field">
            <p className="label" style={{ paddingTop: '10px' }}>
              1. Eser:
            </p>
            <div className="field select is-rounded">
              <select name="birsecim" ref={register} onChange={handleChange}>
                <option value="gorsel">Görsel</option>
                <option value="vidyo">Video</option>
              </select>
            </div>
          </div>

          {eser ? (
            <div className="field">
              <label className="label" htmlFor="bireser">
                1. Eserin Video bağlantısını ekleyin:
              </label>
              <input
                className="input is-info"
                name="bireser"
                placeholder="youtube, vimeo veya diğer.."
                ref={register({
                  validate: (value) => value != '',
                })}
              />
              {errors.bireser && (
                <p className="help is-danger">
                  Lütfen Video bağlantısını girin.
                </p>
              )}
            </div>
          ) : (
            <div className="field">
              <label className="label" htmlFor="bireser">
                1. Eserinizi yükleyin lütfen. (En büyük boyut 500 Kilobyte
                olmalıdır) -{' '}
                <a href="https://bit.ly/2Zfl36N" target="_blank">
                  bit.ly/2Zfl36N
                </a>{' '}
                bu link küçültmek için yardımcı olabilir, yüksekliği 1024 pixel
                olarak ayarlayabilirsiniz.
              </label>
              <div id="file-js-example" className="file has-name is-primary">
                <label className="file-label">
                  <input
                    ref={register}
                    className="file-input"
                    type="file"
                    name="bireser"
                    onChange={onFileChange}
                  />
                  <span className="file-cta">
                    <span className="file-label">
                      1. Eser için lütfen dosya seçiniz.
                    </span>
                  </span>
                  <span className="file-name">Henüz Dosya yüklemediniz.</span>
                </label>
              </div>
            </div>
          )}
          <div className="field">
            <label className="label" htmlFor="birkunye">
              1. Eserin künyesi: Lütfen aşağıdaki örnek künyenin yazılış
              biçimini baz alarak yazınız.
            </label>
            <input
              className="input is-info eserKunye"
              name="birkunye"
              style={{ marginBottom: '10px' }}
              placeholder="İsim - tarih - malzeme 1, malzeme 2 ve malzeme 3 - boyut (yükseklik x derinlik x genişlik) / süre (dakika:saniye) - edisyon (varsa)"
              ref={register({
                validate: (value) => value !== '' && value.length < 160,
              })}
            />
            {errors.birkunye && (
              <p className="help is-danger">
                Lütfen boş bırakmayınız ve en fazla 160 karakter giriniz.
              </p>
            )}
          </div>

          <div className="field">
            <label className="label" htmlFor="birkredit">
              1. Görsel atfı (credit): Bu görsele ilişkin (varsa) hak sahibi
              fotoğrafçı, galeri gibi 3. kişi ya da kurumların bilgilerini
              yazınız.
            </label>
            <input
              className="input is-info eserKunye"
              name="birkredit"
              ref={register}
              style={{ marginBottom: '10px' }}
              placeholder="Bu görsele ilişkin (varsa) hak sahibi fotoğrafçı, galeri gibi 3. kişi ya da kurumların bilgilerini yazınız."
            />
          </div>

          <div className="field">
            <p className="label" style={{ paddingTop: '10px' }}>
              2. Eser:
            </p>
            <div className="field select is-rounded">
              <select name="ikisecim" ref={register} onChange={handleChange2}>
                <option value="gorsel">Görsel</option>
                <option value="vidyo">Video</option>
              </select>
            </div>
          </div>

          {eser2 ? (
            <div className="field">
              <label className="label" htmlFor="ikieser">
                2. Eserin Video bağlantısını ekleyin:
              </label>
              <input
                className="input is-info"
                name="ikieser"
                placeholder="youtube, vimeo veya diğer.."
                ref={register({
                  validate: (value) => value != '',
                })}
              />
              {errors.ikieser && (
                <p className="help is-danger">
                  Lütfen Video bağlantısını girin.
                </p>
              )}
            </div>
          ) : (
            <div className="field">
              <label className="label" htmlFor="ikieser">
                2. Eserinizi yükleyin lütfen. (En büyük boyut 500 Kilobyte
                olmalıdır) -{' '}
                <a href="https://bit.ly/2Zfl36N" target="_blank">
                  bit.ly/2Zfl36N
                </a>{' '}
                bu link küçültmek için yardımcı olabilir, yüksekliği 1024 pixel
                olarak ayarlayabilirsiniz.
              </label>
              <div id="file-js-example2" className="file has-name is-primary">
                <label className="file-label">
                  <input
                    ref={register}
                    className="file-input"
                    type="file"
                    name="ikieser"
                    onChange={onFileChange2}
                  />
                  <span className="file-cta">
                    <span className="file-label">
                      2. Eser için lütfen dosya seçiniz.
                    </span>
                  </span>
                  <span className="file-name">Henüz Dosya yüklemediniz.</span>
                </label>
              </div>
            </div>
          )}
          <div className="field">
            <label className="label" htmlFor="ikikunye">
              2. Eserin künyesi: Lütfen aşağıdaki örnek künyenin yazılış
              biçimini baz alarak yazınız.
            </label>
            <input
              className="input is-info eserKunye"
              name="ikikunye"
              style={{ marginBottom: '10px' }}
              placeholder="İsim - tarih - malzeme 1, malzeme 2 ve malzeme 3 - boyut (yükseklik x derinlik x genişlik) / süre (dakika:saniye) - edisyon (varsa)"
              ref={register({
                validate: (value) => value !== '' && value.length < 160,
              })}
            />
            {errors.ikikunye && (
              <p className="help is-danger">
                Lütfen boş bırakmayınız ve en fazla 160 karakter giriniz.
              </p>
            )}
          </div>

          <div className="field">
            <label className="label" htmlFor="ikikredit">
              2. Görsel atfı (credit): Bu görsele ilişkin (varsa) hak sahibi
              fotoğrafçı, galeri gibi 3. kişi ya da kurumların bilgilerini
              yazınız.
            </label>
            <input
              className="input is-info eserKunye"
              name="ikikredit"
              ref={register}
              style={{ marginBottom: '10px' }}
              placeholder="Bu görsele ilişkin (varsa) hak sahibi fotoğrafçı, galeri gibi 3. kişi ya da kurumların bilgilerini yazınız."
            />
          </div>

          <div className="field">
            <p className="label" style={{ paddingTop: '10px' }}>
              3. Eser:
            </p>
            <div className="field select is-rounded">
              <select name="ucsecim" ref={register} onChange={handleChange3}>
                <option value="gorsel">Görsel</option>
                <option value="vidyo">Video</option>
              </select>
            </div>
          </div>

          {eser3 ? (
            <div className="field">
              <label className="label" htmlFor="uceser">
                3. Eserin Video bağlantısını ekleyin:
              </label>
              <input
                className="input is-info"
                name="uceser"
                placeholder="youtube, vimeo veya diğer.."
                ref={register({
                  validate: (value) => value != '',
                })}
              />
              {errors.uceser && (
                <p className="help is-danger">
                  Lütfen Video bağlantısını girin.
                </p>
              )}
            </div>
          ) : (
            <div className="field">
              <label className="label" htmlFor="uceser">
                3. Eserinizi yükleyin lütfen. (En büyük boyut 500 Kilobyte
                olmalıdır) -{' '}
                <a href="https://bit.ly/2Zfl36N" target="_blank">
                  bit.ly/2Zfl36N
                </a>{' '}
                bu link küçültmek için yardımcı olabilir, yüksekliği 1024 pixel
                olarak ayarlayabilirsiniz.
              </label>
              <div id="file-js-example3" className="file has-name is-primary">
                <label className="file-label">
                  <input
                    ref={register}
                    className="file-input"
                    type="file"
                    name="uceser"
                    onChange={onFileChange3}
                  />
                  <span className="file-cta">
                    <span className="file-label">
                      3. Eser için lütfen dosya seçiniz.
                    </span>
                  </span>
                  <span className="file-name">Henüz Dosya yüklemediniz.</span>
                </label>
              </div>
            </div>
          )}
          <div className="field">
            <label className="label" htmlFor="uckunye">
              3. Eserin künyesi: Lütfen aşağıdaki örnek künyenin yazılış
              biçimini baz alarak yazınız.
            </label>
            <input
              className="input is-info eserKunye"
              name="uckunye"
              style={{ marginBottom: '10px' }}
              placeholder="İsim - tarih - malzeme 1, malzeme 2 ve malzeme 3 - boyut (yükseklik x derinlik x genişlik) / süre (dakika:saniye) - edisyon (varsa)"
              ref={register({
                validate: (value) => value !== '' && value.length < 160,
              })}
            />
            {errors.uckunye && (
              <p className="help is-danger">
                Lütfen boş bırakmayınız ve en fazla 160 karakter giriniz.
              </p>
            )}
          </div>

          <div className="field">
            <label className="label" htmlFor="uckredit">
              3. Görsel atfı (credit): Bu görsele ilişkin (varsa) hak sahibi
              fotoğrafçı, galeri gibi 3. kişi ya da kurumların bilgilerini
              yazınız.
            </label>
            <input
              className="input is-info eserKunye"
              name="uckredit"
              ref={register}
              style={{ marginBottom: '10px' }}
              placeholder="Bu görsele ilişkin (varsa) hak sahibi fotoğrafçı, galeri gibi 3. kişi ya da kurumların bilgilerini yazınız."
            />
          </div>

          <div className="field">
            <p className="label" style={{ paddingTop: '10px' }}>
              4. Eser:
            </p>
            <div className="field select is-rounded">
              <select name="dortsecim" ref={register} onChange={handleChange4}>
                <option value="gorsel">Görsel</option>
                <option value="vidyo">Video</option>
              </select>
            </div>
          </div>

          {eser4 ? (
            <div className="field">
              <label className="label" htmlFor="dorteser">
                4. Eserin Video bağlantısını ekleyin:
              </label>
              <input
                className="input is-info"
                name="dorteser"
                placeholder="youtube, vimeo veya diğer.."
                ref={register({
                  validate: (value) => value != '',
                })}
              />
              {errors.dorteser && (
                <p className="help is-danger">
                  Lütfen Video bağlantısını girin.
                </p>
              )}
            </div>
          ) : (
            <div className="field">
              <label className="label" htmlFor="dorteser">
                4. Eserinizi yükleyin lütfen. (En büyük boyut 500 Kilobyte
                olmalıdır) -{' '}
                <a href="https://bit.ly/2Zfl36N" target="_blank">
                  bit.ly/2Zfl36N
                </a>{' '}
                bu link küçültmek için yardımcı olabilir, yüksekliği 1024 pixel
                olarak ayarlayabilirsiniz.
              </label>
              <div id="file-js-example4" className="file has-name is-primary">
                <label className="file-label">
                  <input
                    ref={register}
                    className="file-input"
                    type="file"
                    name="dorteser"
                    onChange={onFileChange4}
                  />
                  <span className="file-cta">
                    <span className="file-label">
                      4. Eser için lütfen dosya seçiniz.
                    </span>
                  </span>
                  <span className="file-name">Henüz Dosya yüklemediniz.</span>
                </label>
              </div>
            </div>
          )}
          <div className="field">
            <label className="label" htmlFor="dortkunye">
              4. Eserin künyesi: Lütfen aşağıdaki örnek künyenin yazılış
              biçimini baz alarak yazınız.
            </label>
            <input
              className="input is-info eserKunye"
              name="dortkunye"
              style={{ marginBottom: '10px' }}
              placeholder="İsim - tarih - malzeme 1, malzeme 2 ve malzeme 3 - boyut (yükseklik x derinlik x genişlik) / süre (dakika:saniye) - edisyon (varsa)"
              ref={register({
                validate: (value) => value !== '' && value.length < 160,
              })}
            />
            {errors.dortkunye && (
              <p className="help is-danger">
                Lütfen boş bırakmayınız ve en fazla 160 karakter giriniz.
              </p>
            )}
          </div>

          <div className="field">
            <label className="label" htmlFor="dortkredit">
              4. Görsel atfı (credit): Bu görsele ilişkin (varsa) hak sahibi
              fotoğrafçı, galeri gibi 3. kişi ya da kurumların bilgilerini
              yazınız.
            </label>
            <input
              className="input is-info eserKunye"
              name="dortkredit"
              ref={register}
              style={{ marginBottom: '10px' }}
              placeholder="Bu görsele ilişkin (varsa) hak sahibi fotoğrafçı, galeri gibi 3. kişi ya da kurumların bilgilerini yazınız."
            />
          </div>

          <div className="field">
            <p className="label" style={{ paddingTop: '10px' }}>
              5. Eser:
            </p>
            <div className="field select is-rounded">
              <select name="bessecim" ref={register} onChange={handleChange5}>
                <option value="gorsel">Görsel</option>
                <option value="vidyo">Video</option>
              </select>
            </div>
          </div>

          {eser5 ? (
            <div className="field">
              <label className="label" htmlFor="beseser">
                5. Eserin Video bağlantısını ekleyin:
              </label>
              <input
                className="input is-info"
                name="beseser"
                placeholder="youtube, vimeo veya diğer.."
                ref={register({
                  validate: (value) => value != '',
                })}
              />
              {errors.beseser && (
                <p className="help is-danger">
                  Lütfen Video bağlantısını girin.
                </p>
              )}
            </div>
          ) : (
            <div className="field">
              <label className="label" htmlFor="beseser">
                5. Eserinizi yükleyin lütfen. (En büyük boyut 500 Kilobyte
                olmalıdır) -{' '}
                <a href="https://bit.ly/2Zfl36N" target="_blank">
                  bit.ly/2Zfl36N
                </a>{' '}
                bu link küçültmek için yardımcı olabilir, yüksekliği 1024 pixel
                olarak ayarlayabilirsiniz.
              </label>
              <div id="file-js-example5" className="file has-name is-primary">
                <label className="file-label">
                  <input
                    ref={register}
                    className="file-input"
                    type="file"
                    name="beseser"
                    onChange={onFileChange5}
                  />
                  <span className="file-cta">
                    <span className="file-label">
                      5. Eser için lütfen dosya seçiniz.
                    </span>
                  </span>
                  <span className="file-name">Henüz Dosya yüklemediniz.</span>
                </label>
              </div>
            </div>
          )}
          <div className="field">
            <label className="label" htmlFor="beskunye">
              5. Eserin künyesi: Lütfen aşağıdaki örnek künyenin yazılış
              biçimini baz alarak yazınız.
            </label>
            <input
              className="input is-info eserKunye"
              name="beskunye"
              style={{ marginBottom: '10px' }}
              placeholder="İsim - tarih - malzeme 1, malzeme 2 ve malzeme 3 - boyut (yükseklik x derinlik x genişlik) / süre (dakika:saniye) - edisyon (varsa)"
              ref={register({
                validate: (value) => value !== '' && value.length < 160,
              })}
            />
            {errors.beskunye && (
              <p className="help is-danger">
                Lütfen boş bırakmayınız ve en fazla 160 karakter giriniz.
              </p>
            )}
          </div>

          <div className="field">
            <label className="label" htmlFor="beskredit">
              5. Görsel atfı (credit): Bu görsele ilişkin (varsa) hak sahibi
              fotoğrafçı, galeri gibi 3. kişi ya da kurumların bilgilerini
              yazınız.
            </label>
            <input
              className="input is-info eserKunye"
              name="beskredit"
              ref={register}
              style={{ marginBottom: '10px' }}
              placeholder="Bu görsele ilişkin (varsa) hak sahibi fotoğrafçı, galeri gibi 3. kişi ya da kurumların bilgilerini yazınız."
            />
          </div>

          <div className="field">
            <label className="label">
              Sanatçı artvizitleri yalnızca referans yolu ile yaratılıyor.
              Referans olabileceğiniz, çağdaş sanat alanında üretim yapan ve
              Türkiye ile bağlantısı olan beş sanatçının isim ve e-posta
              adreslerini aşağıya yazınız.
            </label>
            <label className="label" htmlFor="isim1">
              1. Sanatçı Ad-Soyad ve E-posta adresi (Lütfen referans verdiğiniz
              sanatçınin mail adresinin doğruluğundan emin olunuz.)
            </label>
            <div className="field">
              <input
                className="input is-info"
                name="isim1"
                placeholder="Sanatçı Adı"
                ref={register({
                  validate: (value) => value.length >= 3,
                })}
              />
              {errors.isim1 && (
                <p className="help is-danger">
                  En az 3 sanatçı paylaşınız lütfen.
                </p>
              )}
            </div>
            <div className="field">
              <input
                className="input is-info"
                name="soyad1"
                placeholder="Sanatçı Soyadı"
                ref={register({
                  validate: (value) => value.length >= 2,
                })}
              />
              {errors.soyad1 && (
                <p className="help is-danger">
                  En az 3 sanatçı paylaşınız lütfen.
                </p>
              )}
            </div>
            <input
              className="input is-info"
              name="email1"
              placeholder="Sanatçı Eposta adresi"
              type="email"
              ref={register({
                validate: (value) => value.length >= 2,
              })}
            />
            {errors.email1 && (
              <p className="help is-danger">
                En az 3 sanatçı paylaşınız lütfen.
              </p>
            )}
          </div>

          <div className="field">
            <label className="label" htmlFor="isim2">
              2. Sanatçı Ad-Soyad ve E-posta adresi (Lütfen referans verdiğiniz
              sanatçınin mail adresinin doğruluğundan emin olunuz.)
            </label>
            <div className="field">
              <input
                className="input is-info"
                name="isim2"
                placeholder="Sanatçı Adı"
                ref={register({
                  validate: (value) => value.length >= 3,
                })}
              />
              {errors.isim2 && (
                <p className="help is-danger">
                  En az 3 sanatçı paylaşınız lütfen.
                </p>
              )}
            </div>
            <div className="field">
              <input
                className="input is-info"
                name="soyad2"
                placeholder="Sanatçı Soyadı"
                ref={register({
                  validate: (value) => value.length >= 2,
                })}
              />
              {errors.soyad2 && (
                <p className="help is-danger">
                  En az 3 sanatçı paylaşınız lütfen.
                </p>
              )}
            </div>
            <input
              className="input is-info"
              name="email2"
              placeholder="Sanatçı Eposta adresi"
              type="email"
              ref={register({
                validate: (value) => value.length >= 2,
              })}
            />
            {errors.email2 && (
              <p className="help is-danger">
                En az 3 sanatçı paylaşınız lütfen.
              </p>
            )}
          </div>

          <div className="field">
            <label className="label" htmlFor="isim3">
              3. Sanatçı Ad-Soyad ve E-posta adresi (Lütfen referans verdiğiniz
              sanatçınin mail adresinin doğruluğundan emin olunuz.)
            </label>
            <div className="field">
              <input
                className="input is-info"
                name="isim3"
                placeholder="Sanatçı Adı"
                ref={register({
                  validate: (value) => value.length >= 3,
                })}
              />
              {errors.isim3 && (
                <p className="help is-danger">
                  En az 3 sanatçı paylaşınız lütfen.
                </p>
              )}
            </div>
            <div className="field">
              <input
                className="input is-info"
                name="soyad3"
                placeholder="Sanatçı Soyadı"
                ref={register({
                  validate: (value) => value.length >= 2,
                })}
              />
              {errors.soyad3 && (
                <p className="help is-danger">
                  En az 3 sanatçı paylaşınız lütfen.
                </p>
              )}
            </div>
            <input
              className="input is-info"
              name="email3"
              placeholder="Sanatçı Eposta adresi"
              type="email"
              ref={register({
                validate: (value) => value.length >= 2,
              })}
            />
            {errors.email3 && (
              <p className="help is-danger">
                En az 3 sanatçı paylaşınız lütfen.
              </p>
            )}
          </div>

          <div className="field">
            <label className="label" htmlFor="isim1">
              4. Sanatçı Ad-Soyad ve E-posta adresi (Lütfen referans verdiğiniz
              sanatçınin mail adresinin doğruluğundan emin olunuz.)
            </label>
            <div className="field">
              <input
                className="input is-info"
                name="isim4"
                placeholder="Sanatçı Adı"
                ref={register}
              />
            </div>
            <div className="field">
              <input
                className="input is-info"
                name="soyad4"
                placeholder="Sanatçı Soyadı"
                ref={register}
              />
            </div>
            <input
              className="input is-info"
              name="email4"
              placeholder="Sanatçı Eposta adresi"
              type="email"
              ref={register}
            />
          </div>

          <div className="field">
            <label className="label" htmlFor="isim1">
              5. Sanatçı Ad-Soyad ve E-posta adresi (Lütfen referans verdiğiniz
              sanatçınin mail adresinin doğruluğundan emin olunuz.)
            </label>
            <div className="field">
              <input
                className="input is-info"
                name="isim5"
                placeholder="Sanatçı Adı"
                ref={register}
              />
            </div>
            <div className="field">
              <input
                className="input is-info"
                name="soyad5"
                placeholder="Sanatçı Soyadı"
                ref={register}
              />
            </div>
            <input
              className="input is-info"
              name="email5"
              placeholder="Sanatçı Eposta adresi"
              type="email"
              ref={register}
            />
          </div>

          <div className="field">
            <label className="checkbox">
              <input
                type="checkbox"
                name="kontrol1"
                ref={register({
                  validate: (value) => value === true,
                })}
              />
              {`    `}{' '}
              <a href={downloadFile} target="_blank">
                Aydınlatma metnini
              </a>{' '}
              okudum ve kabul ediyorum.
            </label>
          </div>
          {errors.kontrol1 && (
            <p className="help is-danger">Onaylamanız gerekiyor.</p>
          )}

          <div className="field">
            <label className="checkbox">
              <input
                type="checkbox"
                name="kontrol2"
                ref={register({
                  validate: (value) => value === true,
                })}
              />
              {`    `} Yukarıda yer alan bilgilerin bana ait ve güncel olduğunu
              beyan ederim.
            </label>
          </div>
          {errors.kontrol2 && (
            <p className="help is-danger">Onaylamanız gerekiyor.</p>
          )}

          <div className="field">
            <label className="checkbox">
              <input
                type="checkbox"
                name="kontrol3"
                ref={register({
                  validate: (value) => value === true,
                })}
              />
              {`   `} Yüklediğim görseller üzerinde fotoğrafçı, galeri gibi
              herhangi başka bir üçüncü kişi ya da kurumun hak sahibi olması
              halinde, görselin Artvizit.co’da yayınlanması için gerekli tüm
              izinleri aldığımı ve hak sahiplerine dair gerekli atıfları
              (credit) yaptığımı kabul ederim.
            </label>
          </div>
          {errors.kontrol3 && (
            <p className="help is-danger">Onaylamanız gerekiyor.</p>
          )}

          <div className="field">
            <label className="checkbox">
              <input
                type="checkbox"
                name="kontrol4"
                ref={register({
                  validate: (value) => value === true,
                })}
              />
              {`   `} Artvizit.co’da benimle ilgili bir sanatçı sayfası
              oluşturulması ve bu sayfada bilgilerimin ve eserlerimin
              yayınlanması ile ilgili olarak herhangi bir kapsamda temsil
              ilişkisinde olduğum tüm kurumlardan (örn. Galeri) gerekli ise onay
              aldığımı beyan ederim. İlgili kurumların taleplerine ilişkin tek
              sorumlumu olduğumu ve bu kurumların herhangi bir talebine karşılık
              Artvizit.co ve çalışanlarını tazmin edeceğimi kabul ve beyan
              ederim.
            </label>
          </div>
          {errors.kontrol4 && (
            <p className="help is-danger">Onaylamanız gerekiyor.</p>
          )}

          <div className="control">
            <button
              ref={btnRef}
              className="control button is-primary"
              type="submit"
            >
              Gönder!
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default Gonder
